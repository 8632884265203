<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import { required } from "vuelidate/lib/validators";
import Loans from "./loans.vue";
import Savings from "./savings.vue";
import axios from "axios";
import errorHandling from "../../../mixins/errorHandling";

/**
 * Product Detail Component
 */
export default {
  mixins: [errorHandling],
  components: {
    Layout,
    PageHeader,
    Loans,
    Savings,
  },
  data() {
    return {
      title: "Verify Loan Payment",
      items: [
        {
          text: "Agent",
        },
        {
          text: "Loan",
          active: true,
        },
      ],
      customer: "",
      customerImage: "",
      selected: null,
      options: [],
      loan: [],
      submitted: false,
      name: "",
      loanName: "",
      phone: "",
      amount: "",
      verifyAmount: "",
    };
  },
  validations: {
    name: { required },
    loanName: { required },
    phone: { required },
    amount: { required },
    verifyAmount: { required },
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    /**
     * Selected image shows
     */
    imageShow(event) {
      const image = event.target.src;
      const expandImg = document.getElementById("expandedImg");
      expandImg.src = image;
    },
    async getCustomer() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/agent/get-one-customer-by-id/${this.$route.params.id}`
        );
        if (response.status === 200 || response.status === 201) {
          this.customer = response.data.data;
        } else {
          this.throwError();
        }
      } catch (error) {
        this.handleError(error.response.data.message);
      }
    },
    async getCustomerLoan() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/agent/get-customer-loan/${this.selected}`
        );
        if (response.status === 200 || response.status === 201) {
          this.loan = response.data.data.summary;
        } else {
          this.throwError();
        }
      } catch (error) {
        this.handleError(error.response.data.message);
      }
    },

    async getCustomerImage() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/agent/get-customer-image/${this.$route.params.id}`
        );
        if (response.status === 200 || response.status === 201) {
          this.customerImage = response.data.data;
        } else {
          this.throwError();
        }
      } catch (error) {
        this.handleError("Error fetching customer image");
      }
    },
  },
  async mounted() {
    this.getCustomer();
    this.getCustomerImage();
    // const customerAccount = await this.getCustomerAccount()
    // this.options = customerAccount.map((item) => ({ value: item.id, text: item.productName + " - " + item.id}));
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="col-12 m-auto p-4">
            <div class="row">
              <img
                id="expandedImg"
                :src="customerImage"
                width="200"
                class="img-fluid mx-auto d-block"
              />
            </div>
            <!-- end product img -->
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-xl-12">
                <div class="mt-4 mt-xl-3">
                  <p class="text-primary font-size-20 mb-2 text-center">
                    Customer Accounts
                  </p>

                  <div class="row">
                    <div class="col-md-4">
                      <div class="card">
                        <div class="card-body">
                          <div class="media">
                            <div class="media-body overflow-hidden">
                              <p class="text-truncate font-size-14 mb-2">
                                Customer Name
                              </p>
                              <h4 class="mb-0">{{ customer.displayName }}</h4>
                            </div>
                            <div class="text-primary">
                              <i class="ri-user-3-line font-size-24"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="card">
                        <div class="card-body">
                          <div class="media">
                            <div class="media-body overflow-hidden">
                              <p class="text-truncate font-size-14 mb-2">
                                Phone Number
                              </p>
                              <h4 class="mb-0">{{ customer.mobileNo }}</h4>
                            </div>
                            <div class="text-primary">
                              <i class="ri-phone-line font-size-24"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="card">
                        <div class="card-body">
                          <div class="media">
                            <div class="media-body overflow-hidden">
                              <p class="text-truncate font-size-14 mb-2">
                                Account Number
                              </p>
                              <h4 class="mb-0">{{ customer.accountNo }}</h4>
                            </div>
                            <div class="text-primary">
                              <i class="ri-hashtag font-size-24"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="col-lg-12"></div>
                  </div>
                  <div>
                    <Loans />
                  </div>
                  <div>
                    <Savings />
                  </div>
                  <hr class="my-4" />
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
#expandedImg {
  margin: 0 auto;
}
</style>
